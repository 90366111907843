// find detailed information about the vvo api responses here:
// https://github.com/kiliankoe/vvo/blob/main/documentation/webapi.md


export async function findStop(query: string) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: `{"query":${query}, "limit": "15", "regionalOnly": "true", "stopsOnly": "true"}`
  };

  return fetch('https://webapi.vvo-online.de/tr/pointfinder', options)
    .then(response => response.json())
    .catch(err => console.error(err));
}

export function convertStopsToJSON(stops: any) {
  let stopsJSON: any = [];
  if (stops && stops.length > 0) {
    stops.forEach((stop: any) => {
      let stopJSON: any = {};
      let stopList: any = stop.split("|");

      stopJSON.id = stopList[0];
      stopJSON.city = stopList[2];
      stopJSON.name = stopList[3];
      stopsJSON.push(stopJSON);
    });
  }
  return stopsJSON;
}


export interface routeProps {
  stationDep: string;
  stationDest: string;
  dateDep: string;
  timeDep: string;
  depArr: "an" | "ab";
}

function convertTime(time: string): string {

    // make into time range
    if (time == "morgens") {
        time = "zwischen 07:00 Uhr und 11:00 Uhr"
    }
    if (time == "vormittags") {
        time = "zwischen 10:30 Uhr und 11:59 Uhr"
    }
    if (time == "mittags") {
        time = "12:00 Uhr"
    }
    if (time == "nachmittags") {
        time = "zwischen 12:30 Uhr und 16:59 Uhr"
    }
    if (time == "abends") {
        time = "zwischen 17:00 Uhr und 20:00 Uhr"
    }
    if (time == "nachts") {
        time = "zwischen 20:00 Uhr und 01:00 Uhr"
    }

    // handle time range
    if (time.includes("zwischen")) {
        return time.substring(9, 18)
    }
    return time
}

export function EvalDVBDate(date: string) {
  const regex = /^\/Date\([0-9]+-[0-9]+\)\/$/i;
  const match = regex.exec(date);

  if (match) {
    const dateToEval = date.substring(1, date.length-1).replace("-", "-0o");
    return eval("new " + dateToEval);
  } else {
    console.error("Invalid timestamp format.");
    return new Date(9999, 9, 9, 9, 9);
  }
}

export async function findRoutes(props: routeProps) {
  console.log("findRoutes: props", props)
  let date = new Date(props.dateDep);
  console.log("findRoutes props.timeDep:", props.timeDep)
  let time = new Date("1.1.1000 " + convertTime(props.timeDep));
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());

  let dateString = date.toISOString();
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: `
    {
      "format":"json",
      "origin":${props.stationDep},
      "destination":${props.stationDest},
      "city_origin":"Dresden",
      "isarrivaltime":${props.depArr === "an"},
      "shorttermchanges":true,
      "time":${dateString},
      "via":null,
      "limit":10
    }`
  };

  try {
    const response = await fetch('https://webapi.vvo-online.de/tr/trips', options);
    return await response.json();
  } catch (err) {
    return console.error(err);
  }
}
