export const data = {
    "prostate": {
        "length": 0,
        "width": 0,
        "height": 0,
        "volume": 0
    },
    "psa": {
        "value": 0,
        "density": 0
    }
}
