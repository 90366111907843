import React from "react";

interface DatepickerProps {
  id: string;
  title: string;
  description: string;
  value: any;
  handleChange: any;
  path: string;
}

export default function Datepicker({ id, title, description, value, handleChange, path }: DatepickerProps) {
  return (
    <>
      <div className="form-floating mb-3">
        <input
          className="form-control"
          id={id}
          type="date"
          placeholder="tt.mm.jjjj"
          onChange={(e: any) => handleChange(path, e.target.value)}
          value={value}
        />
        <label htmlFor={id}>{title}</label>
        <div className="form-text">
          {description}
        </div>
      </div>
    </>
  )
}
