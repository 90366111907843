import React from "react";
import ReactDomServer from "react-dom/server";
import "../css/HypothesisTextarea.css";
import { state } from "@linguwerk/asr_kit";

let currentTimeout: any;

interface HypothesisTextareaProps {
  state: number;
  hypothesis: string;
  setHypothesis: any;
}

export function HypothesisTextarea(props: HypothesisTextareaProps) {
  let innerHTML: any = "";
  switch (props.state) {
    case state.off:
      break;
    case state.connecting:
      innerHTML = ReactDomServer.renderToString(<Connecting />);
      break;
    case state.streaming:
      innerHTML = ReactDomServer.renderToString(<SpeechIndicator />);
    case state.connected:
      innerHTML += props.hypothesis;
      if (props.hypothesis && props.hypothesis !== "") {
        if (currentTimeout) {
          clearTimeout(currentTimeout)
        };
        currentTimeout = setTimeout(() => {
          props.setHypothesis("");
        }, 5_000);
      }
      break;
  }

  return (
    <div id="hypothesisField"
      dangerouslySetInnerHTML={{ __html: innerHTML }}
      style={{
        position: "fixed",
        width: "fit-content",
        maxWidth: "100%",
        minWidth: "0",
        border: "none",
        background: "black",
        height: "fit-content",
        minHeight: "0",
        zIndex: 701,
        color: "white",
        fontWeight: "500",
        padding: props.hypothesis ? "5px" : "0px",
        fontSize: "x-large",
      }}>
    </div>
  )
}

function Connecting() {
  return (
    <div style={{ background: "black", padding: "2px" }}>
      <span style={{ color: "white" }}>Connecting...</span>
    </div>
  )
}

function SpeechIndicator() {
  return (
    <span className="speechIndicator">
      <span className="outerLine"></span>
      <span className="innerLine"></span>
      <span className="outerLine"></span>
    </span>
  )
}
