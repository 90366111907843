import { withJsonFormsControlProps } from '@jsonforms/react';
import Timepicker from '../renderers/bootstrapped/Timepicker';
import React from 'react';

interface TimepickerControlProps {
  id: string;
  data: any;
  handleChange(value: string): void;
  schema: any;
}

function TimepickerControl (props: any){
    return (
      <Timepicker
        id={props.id}
        title={props.schema.title}
        description={props.schema.description}
        value={props.data}
        handleChange={props.handleChange}
        path={props.path}
      />
    )
}

export default withJsonFormsControlProps(TimepickerControl);
