export const scheme = {
    type: "string",
    enum: [
        "1",
        "2",
        "3",
        "4",
        "5"
    ]
}
