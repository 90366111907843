import { DataItem } from '../components/Viewer';
import { DateTime } from 'luxon';
import CONFIG from "../res/config";

export async function updateMarker(domainSelection: string, event_id: number, is_marked: boolean) {
    if (is_marked) {
        await query_events(domainSelection, `INSERT INTO is_marked VALUES(${event_id});`);
    }
    else {
        await query_events(domainSelection, `DELETE FROM is_marked WHERE event_id=${event_id};`);
    }
}

export async function fetchEvents(domainSelection: string) {
  let allResults: DataItem[] = [];
  let results: any;

  try {
    results = await query_events(domainSelection, `SELECT * FROM events ORDER BY timestamp DESC;`);
  } catch (error) {
    console.error('Error executing query:', error);
    return [];
  }

  console.log(results)

  allResults = await format_events(domainSelection, results);
  console.log("fetchEvents allResults:", allResults);

  return allResults;
}

async function query_events(domainSelection: string, query: string) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: CONFIG.db.auth },
    body: `{"query": "${query}", "nluConfig": {"domain": "${domainSelection}"}}`
  };

  const response = await fetch(`${CONFIG.db.url}/events`, options);
  if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
  }


  const responseData = await response.json();
  return responseData.data;
}

async function format_events(domainSelection: string, events: any) {
    /**
        Converts the database output for a session to a JSON that is more readable and easier to handle.
        In this case, we want:
        - ID: The ID of the event in the data table
        - date/time: timestamp of the user input
        - sender_id:
        - user_input: The user utterance
        - intent: the recognizes intent for the user input
        - entities: entities and their values in the user input
        - raw: the JSON-reply the bot sent
        - is_marked: if this event was marked or not
        To get raw, we must match the bot response to the user input.
    **/

    const resultData : Partial<DataItem>[] = [];

    for (const result of events) {
        let data = JSON.parse(result[result.length-1]);

//         // Get information from user input
//         if (data.event === 'user') {
//             let text = data.text;
//             data.parse_data.entities = data.parse_data.entities.sort((a:any, b:any) => b.end - a.end);
//
//             for (const entity of data.parse_data.entities) {
//                 text = `${text.slice(0, entity.start)} (${entity.entity}:${text.slice(entity.start, entity.end)}->${entity.value}) ${text.slice(entity.end)}`;
//             }
//
//             const userEvent : Partial<DataItem> =
//             {
//                 id: result[0],
//                 date: convertTimestampToString(result[3]),
//                 sender_id: result[1],
//                 user_input: data.text,
//                 intent: data.parse_data.intent.name.split('+').join('+ '),
//                 entities: data.entities,
//                 // raw: data from reply
//                 is_marked: false,
//             };
//             resultData.push(userEvent);
//         }

        // get info for reply
        if (data.event === 'bot') {
            let raw_data: any = data.data.custom;
            let { text } = raw_data;
            let { intent } = raw_data;
            let { session_slots } = raw_data;
            let { entities } = raw_data;

            if (intent === undefined) {
                  intent = "";
            }

            let entities_formatted: any[] = [];
            if (entities !== undefined) {
                for (const ent of entities) {
                  if ("role" in ent) {
                      entities_formatted.push({"entity": `${ent.entity}/${ent.role}`, "value": ent.value});
                  }
                  else {
                      entities_formatted.push(ent);
                  }
                }
            }

            const rowEvent :DataItem =
              {
                  id: result[0],
                  date: convertTimestampToString(result[3]),
                  sender_id: result[1],
                  user_input: text,
                  intent: intent.split('+').join(' +'),
                  entities: entities_formatted,
                  raw: session_slots,
                  is_marked: false,
              };

            resultData.push(rowEvent);
        }

//         if (data.event === 'slot') {
//             const slotEvent = { event: 'slot', text: data.name, name: data.value, raw: data };
//             resultData.events.push(slotEvent);
//         }
//         // prints actions
//         if (data.event === 'action') {
//             resultData.events.push({ event: 'action', text: '', name: data.name, raw: data });
//         }
    }

    // get is_marked and merge where ID matches
    const markers: number[] = (await query_events(domainSelection, `SELECT event_id FROM is_marked;`)).map((m: [number]) => m[0]);
    if (Array.isArray(markers)) {
        for (const res of resultData) {
          if (res.id !== undefined && markers.includes(res.id)) {
            res.is_marked = true;
          }
        }
    }
    else {
        console.log("format_events, markers:", markers);
    }
    let complete_result: DataItem[] = resultData as DataItem[];

    return complete_result;
}

function convertTimestampToString(timestamp:number) {
    return DateTime.fromMillis(timestamp * 1000, { zone: 'Europe/Berlin' }).toFormat('dd.MM.yyyy HH:mm');
}