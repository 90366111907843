export const data =
{
    "lesions":
    [
        {
            "lesionNum": 1,
            "zone": null,
            "side": null,
            "level": null,
            "size": 0.0,
            "t2": null,
            "dwi": null,
            "dce": null,
            "pirads": null,
        }
    ]
}