import React, { useState } from "react";
import { data as prostateDefaultData } from "../scheme/prostate/data";
import { data as lesionListDefaultData } from "../scheme/lesions/lesionListDefaultData";

export const AppStateContext = React.createContext({});

/**
 * Provides all **top level** states of the app.
 * @param param0 
 * @returns 
 */
export function AppStateProvider({ children }: any) {
    const [technology, setTechnology] = useState("");
    const [medicalHistory, setMedicalHistory] = useState("");
    const [prostateData, setProstateData] = useState(prostateDefaultData);
    const [psaData, setPsaData] = useState("");
    const [PIRADS, setPIRADS] = useState("not defined");
    const [lesionList, setLesionList] = useState(lesionListDefaultData);

    return (
        <AppStateContext.Provider value={{
            technology, setTechnology,
            medicalHistory, setMedicalHistory,
            prostateData, setProstateData,
            psaData, setPsaData,
            lesionList, setLesionList,
            PIRADS, setPIRADS
        }}>
            {children}
        </AppStateContext.Provider>
    )
}
