import React, { useEffect, useRef, useState } from "react";
import "../css/ASRButton.css";
import { state } from "@linguwerk/asr_kit";
import { faMicrophone, faMicrophoneSlash, faSpinner, faEarListen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HypothesisToken } from "@linguwerk/asr_kit";

type ASRButtonProps = {
  currentState: number;
  setState: any;
}

/**
 * Renders Button for current state
 * @param props 
 * @returns 
 */
export function ASRButton(props: ASRButtonProps) {
  const stateRef = useRef(0);
  stateRef.current = props.currentState;
  const clickEvent = (x: any) => onButtonClick(stateRef, props.setState);
  const mouseDownEvent = (event: any) => { event.preventDefault(); onMouseDown(stateRef, props.setState); };
  const mouseUpEvent = (event: any) => { event.preventDefault(); onMouseUp(stateRef, props.setState); };
  const buttonRef = useRef(null);

  useEffect(() => {
    const buttonElement: any = buttonRef.current;

    if (buttonElement) {
      buttonElement.addEventListener('touchend', mouseUpEvent, { passive: false });
      buttonElement.addEventListener('touchstart', mouseDownEvent, { passive: false });
    }

    return () => {
      if (buttonElement) {
        buttonElement.removeEventListener('touchend', mouseUpEvent);
        buttonElement.removeEventListener('touchstart', mouseDownEvent);
      }
    };
  }, []);

  var icon = faMicrophone;
  var style = {};
  var classes = "";
  switch (props.currentState) {
    case state.off:
    case state.disconnecting:
      classes = "dictationButton dictationButtonOff";
      icon = faMicrophoneSlash;
      break;
    case state.connecting:
      classes = "dictationButton dictationButtonConnecting";
      style = { color: "#3498db", };
      icon = faSpinner;
      break;
    case state.connected:
    case state.mute:
      classes = "dictationButton dictationButtonConnected";
      icon = faMicrophone;
      break;
    case state.streaming:
      classes = "dictationButton";
      icon = faEarListen;
      break;
  }
  return <button
    ref={buttonRef}
    onClick={clickEvent}
    onMouseDown={mouseDownEvent}
    onMouseUp={mouseUpEvent}
    className={classes}
    style={style}
  >
    <FontAwesomeIcon icon={icon} spin={props.currentState === state.connecting} beat={props.currentState === state.streaming} />
  </button>
}

/**
 * Changes State on Blick
 * @param stateRef 
 * @param setButtonState 
 */
function onButtonClick(stateRef: any, setButtonState: any) {
  switch (stateRef.current) {
    case state.connecting:
      setButtonState(state.disconnecting);
      break;
  }
}

function onMouseDown(stateRef: any, setState: any) {
  switch (stateRef.current) {
    case state.connected:
      setState(state.unmute);
      break;
  }
}

function onMouseUp(stateRef: any, setState: any) {
  switch (stateRef.current) {
    case state.streaming:
      setState(state.mute);
      break;
  }
}

/**
 * Common shared functions when working with ASR
 */

export function hypothesisTokenToString(hypothesisToken: HypothesisToken[]) {
  let text = "";
  let space = "";

  hypothesisToken.forEach(({ content }) => {
    if (".,!?:;)".includes(content)) {
      text += content;
      space = " ";
    } else if (content === "("
    ) {
      text += " " + content;
      space = "";
    } else if (content === "\n" || content === "\n\n") {
      text += content;
      space = "";
    } else {
      text += space + content;
      space = " ";
    }
  });
  return text;
}