export const uischema = {
    type: "VerticalLayout",
    elements: [

        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "Zone"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/zone"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "Loc"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/level"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/side"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "T2"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/t2"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "DWI"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/dwi"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "DCE"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/dce"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "PI-RADS"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/pirads"
                        }
                    ]
                }
            ]
        },
        {
            type: "Group",
            elements: [
                {
                    type: "HorizontalLayout",
                    elements: [
                        {
                            type: "Label",
                            text: "Größe"
                        },
                        {
                            type: "Control",
                            scope: "#/properties/size",
                            options: {
                                unit: "cm",
                                min: 0,
                                max: 50,
                                step: 0.1
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
