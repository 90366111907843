import { withJsonFormsControlProps } from '@jsonforms/react';
import { ControlProps, rankWith, schemaTypeIs } from '@jsonforms/core';
import { useContext } from 'react';
import { LesionAreaHeaderContext } from '../context/LesionAreaHeaderContext';
import { TextField, InputAdornment } from '@mui/material';
import { useState, useEffect } from 'react';
import _ from 'lodash';

function LesionNumber(props: ControlProps) {
    const [isJiggling, setIsJiggling] = useState(false);
    const [isFalseInput, setIsFalseInput] = useState(false);
    const [prevData, setPrevData] = useState(props.data);
    const { header } = useContext(LesionAreaHeaderContext);
    const unit = props.uischema?.options?.unit || '';
    const min = props.uischema?.options?.min || '';
    const max = props.uischema?.options?.max || '';
    const step = props.uischema?.options?.step || '';
    var headerEl;
    if (header === true && props.schema.hasOwnProperty('title')) {
        headerEl = (
            <p><label htmlFor={props.id}><b>{props.schema.title} |</b></label></p>
        );
    }

    const triggerJiggle = () => {
        setIsJiggling(true);
        setTimeout(() => setIsJiggling(false), 200); // Duration of the jiggle animation
    };

    if (!_.isEqual(prevData, props.data)) {
        setPrevData(props.data);
        if (props.data !== undefined && props.data !== '') {
            triggerJiggle();
        }
        if (!parseFloat(props.data)) {
            setIsFalseInput(true);
        } else {
            setIsFalseInput(false);
        }
    }

    return (
        <>
            <div className='row'>
                {headerEl}
            </div>
            <div className='row' style={{"margin": "0", "maxWidth": "106px"}}>
                <TextField
                    id={props.id}
                    className={
                        `form-control
                        ${isJiggling ? 'jiggle' : ''}
                        ${isFalseInput ? 'invalidInput' : ''}`
                    }
                    type='number'
                    value={props.data || ''}
                    onChange={
                        (e: any) => { console.log("TARGET",e.target.value); props.handleChange(props.path, parseFloat(e.target.value)) }
                    }
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                        inputProps: {
                          min: min,
                          max: max,
                          step: step
                        }
                    }}
                />
            </div>
        </>
    );
}

const NumberRenderer = withJsonFormsControlProps(LesionNumber);
const NumberTester = rankWith(3, schemaTypeIs('number'));

export {
    NumberRenderer,
    NumberTester
};
