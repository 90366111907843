export const uischema = {
    type: "HorizontalLayout",
    elements: [
        {
            type: "HorizontalLayout",
            elements: [
                {
                    type: "Control",
                    scope: "#/properties/prostate/properties/length",
                    options: {
                        unit: "cm",
                        min: 0,
                        max: 50,
                        step: 0.1
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/prostate/properties/width",
                    options: {
                        unit: "cm",
                        min: 0,
                        max: 50,
                        step: 0.1
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/prostate/properties/height",
                    options: {
                        unit: "cm",
                        min: 0,
                        max: 50,
                        step: 0.1
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/prostate/properties/volume",
                    options: {
                        unit: "cm³",
                        min: 0,
                        max: 500,
                        step: 0.1
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/psa/properties/value",
                    options: {
                        unit: "ng/ml",
                        min: 0,
                        max: 500,
                        step: 0.1
                    }
                },
                {
                    type: "Control",
                    scope: "#/properties/psa/properties/density"
                }
            ]
        }
    ]
}
