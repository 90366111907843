import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useState } from 'react';
import { convertStopsToJSON, findStop } from '../service/DVB';
import _ from 'lodash';

interface InputControlProps {
    id: string;
    data: string;
    handleChange(value: string): void;
    schema: any;
    uischema: any;
    path: string;
}

/**
 * This function is needed to exclude doubled options. This may happen if "stopsOnly" is turned off in StopFinder.
 * @param addedStops stops, which were already added
 * @param stopToCheck current stop which is about being added
 * @returns if stop is not already added
 */
function stopNotListed(addedStops: any, stopToCheck: any): boolean {
    addedStops.forEach((stop: any) => {
        if ((stop.name === stopToCheck.name) && (stop.city === stopToCheck.city)) {
            return false
        }
    })
    return true;
}

export function fetchStopData(stopName: string): { name: string; city: string; id: string; }[] {
    let stops: { name: string; city: string; id: string; }[] = []
    findStop(stopName)
        .then((response: any) => {
            let stops: any = convertStopsToJSON(response.Points);
            //console.log("JSON");
            //console.log(stops);
            return stops
        })

    return stops;
}

function InputControl(props: any) {
    const [options, setOptions] = useState(""); // <option> tags to list possible stops on autocomplete
    const [prevData, setPrevData] = useState(props.data);
    let options_: string = "";
    let stopName: string = "";
    if (props.data !== undefined && !_.isEqual(prevData, props.data)) {
        props.data.stops = [];
        stopName = props.data.name;
        if (
            props.uischema.stopFinder === true // if stops should be checked for that field
            && props.data.name.length >= 3) /** VVO API needs 3+ characters */ {
                findStop(props.data.name)
                .then((response: any) => {
                    console.log("PROPS", _.cloneDeep(props.data));
                    let stops: any = convertStopsToJSON(response.Points);
                    stops.forEach((stop: any) => {
                        if (stopNotListed(props.data.stops, stop)) {
                            options_ += "<option>" + stop.name + (stop.city ? ", " + stop.city : "") + "</option>"
                        }
                        props.data.stops.push({ name: stop.name, city: stop.city, id: stop.id });
                    });
                    props.data.id = setStopId(props.data.name).id;
                    setOptions(options_);
                    console.log("NEW PROPS", _.cloneDeep(props.data));
                    props.handleChange(props.path, _.cloneDeep(props.data));
                    setPrevData(_.cloneDeep(props.data));
                })
        }
    }

    function setStopId(value: string) {
        let id: string = "0";
        let city: string = "";
        let returnValue: any = {};
        if (props.data !== undefined) {
            props.data.stops.every((stop: any) => {
                city = value.split(", ")[1] ? value.split(", ")[1] : "";
                let name: string = value.split(", ")[0]; // split always returns a list with at least one string
                if (name === stop.name && city === stop.city) {
                    id = stop.id
                    return false;
                }
                return true;
            })
            returnValue.city = props.data.city;
            returnValue.id = id;
            returnValue.stops = props.data.stops;

            if (id === "0" && props.data.stops.length > 0) {
                returnValue.id = props.data.stops[0].id;
                returnValue.name = props.data.stops[0].name;
            }
        }

        console.log(`${value}: ${id}`);
        returnValue.name = value;
        return returnValue;
    }
    return (
        <>
            <div className="form-floating mb-3">
                <input
                    className="form-control required-input"
                    id={props.id}
                    type="search"
                    placeholder={"name@example.com"}
                    onChange={(e: any) => {
                        const stopObj = e.target.value != null ? setStopId(e.target.value) : "";
                        props.handleChange(props.path, stopObj);
                    }}
                    value={props.data.name ? props.data.name : ""}
                    list={`list-stops-${props.id}`}
                    autoComplete='off'
                    required
                />
                <label htmlFor={props.id}>{props.schema.title}</label>
                <datalist id={`list-stops-${props.id}`} dangerouslySetInnerHTML={{ __html: options }} />
                <div className="form-text">
                    {props.schema.description}
                </div>
            </div>
        </>
    )
}

export default withJsonFormsControlProps(InputControl);
