import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
      MuiCardHeader: {
          styleOverrides: {
              root: {
                  padding: 0
              }
          },
      },
      MuiCardContent: {
          styleOverrides: {
              root: {
                  paddingBottom: "0 !important"
              }
          },
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontSize: "1rem"
              },
              h6: {
                  width: "75px"
              }
          },
      },
      MuiGrid: {
          styleOverrides: {
              root: {
                  padding: 0
              },
              item: {
                  paddingTop: "0 !important"
              }
          },
      },
      MuiPaper: {
          styleOverrides: {
              root: {
                  boxShadow: "none",
              }
          },
      }
  },
});