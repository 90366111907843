import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
import React from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

const HorizontalLayoutControl = (props: any) => {
  const { uischema, schema, path, visible, renderers } = props;
  let direction: "row" | "column" = "row";
  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: direction,
    visible: visible,
    uischema: uischema,
    renderers: renderers,
  };

  let columnsPerElement = 12 / layoutProps.elements.length;

  let itemList: any = [];
  layoutProps.elements.forEach((element: any, index: number) => {
    let elementProps = {
      ...layoutProps,
      elements: [element]
    };
    itemList.push (
      <div className={`col-12 col-sm-12 col-md-${columnsPerElement}`} key={index}>
        <MaterialLayoutRenderer {...elementProps} />
      </div>
    );
  })

  return (
    <>
    <div className='row'>
      {itemList}
      {window.innerWidth <= 720 ? <hr/> : <></>}
    </div>
    </>
  )
};

export default withJsonFormsLayoutProps(HorizontalLayoutControl);