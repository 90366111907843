function FormDiv({divID, title, children}: any) {
    return (
        <div id={divID} className='formDivLight'>
            <h3>{title}</h3>
            {children}
        </div>
    );
}

export default FormDiv;
