import { withJsonFormsControlProps } from "@jsonforms/react";

type depDestType = "an" | "ab";

function DepDestControl(props: any) {
  console.log("DepDestControl props:", props)
  let isDeparture: boolean = true;
  if (props.data) {
    isDeparture = props.data === "ab";
  }
  return (<>
    <div className="form-check form-check-inline pt-3">
      <input
        className="form-check-input"
        id="departureRadio"
        type="radio"
        name="depArr"
        value={"ab"}
        checked={isDeparture}
        onChange={(e: any) => props.handleChange(props.path, e.target.value)}
      />
      <label className="form-check-label" htmlFor="departureRadio">
        Abfahrt
      </label>
    </div>
    <div className="form-check form-check-inline pt-3">
      <input
        className="form-check-input"
        id="destinationRadio"
        type="radio"
        name="depArr"
        value={"an"}
        checked={!isDeparture}
        onChange={(e: any) => props.handleChange(props.path, e.target.value)}
      />
      <label className="form-check-label" htmlFor="destinationRadio">
        Ankunft
      </label>
    </div>
  </>)
}

export default withJsonFormsControlProps(DepDestControl);