import { useState } from "react"

export const travelInformationCoreSchema = {
  type: "object",
  properties: {
    stationDep: {
      type: "object",
      title: "Von",
      description: "Der Name der Haltestelle, an der die Fahrt beginnt",
    },
    stationDest: {
      type: "object",
      title: "Nach",
      description: "Der Name der Haltestelle, an der die Fahrt endet",
    },
    dateDep: {
      type: "string",
      title: "Datum",
      description: "Das Reisedatum der Hinfahrt"
    },
    timeDep: {
      type: "string",
      title: "Uhrzeit",
      description: "Die Uhrzeit, zu der die Fahrt beginnen soll"
    },
    depArr: {
      type: "string"
    },
    returnJourneyDate: {
      type: "string",
      title: "Rückfahrt",
      description: "Das Reisedatum der Rückfahrt"
    },
    returnJourneyTime: {
      type: "string",
      title: "Rückfahrt",
      description: "Die Uhrzeit, an dem die Fahrt endet"
    },
    returnJourneyDepArr: {
      type: "string",
      title: "",
      enum: [
        "ab",
        "an"
      ]
    },
    fastestConnection: {
      type: "boolean",
      title: "Schnellste Verbindung"
    },
    onlyLocalTransport: {
      type: "boolean",
      title: "Nur Nahverkehr"
    },
    class: {
      type: "string",
      title: "",
      enum: [
        "1. Klasse",
        "2. Klasse"
      ]
    },
  }
}

export const schema = {
  type: "object",
  properties: {
    ...travelInformationCoreSchema.properties
  }
}

export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/stationDep",
          stopFinder: true,
          required: true
        },
        {
          type: "Control",
          scope: "#/properties/stationDest",
          stopFinder: true,
          required: true
        }
      ]
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/dateDep"
        },
        {
          type: "Control",
          scope: "#/properties/timeDep"
        },
        {
          type: "Control",
          scope: "#/properties/depArr"
        }
      ],
    },
    // {
    //   type: "ReturnJourney",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/returnJourneyDate"
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/returnJourneyTime"
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/returnJourneyDepArr",
    //       options: {
    //         format: "radio"
    //       }
    //     }
    //   ],
    // },
    // {
    //   type: "HorizontalLayout",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/fastestConnection"
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/onlyLocalTransport"
    //     }
    //   ],
    // },
    // {
    //   type: "HorizontalLayout",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/class",
    //       options: {
    //         format: "radio"
    //       }
    //     }
    //   ],
    // }
  ]
}

export type dataProps = {
  stationDep: stationProps;
  stationDest: stationProps;
  dateDep: string;
  timeDep: string;

}

type stationProps = {
  name: string;
  city: string;
  id: string;
  stops: stopProps[];
}

type stopProps = {
  name: string;
  city: string;
  id: string;
}

export const data = {
  stationDep: { name: "", city: "", id: "0", stops: [] },
  stationDest: { name: "", city: "", id: "0", stops: [] },
  dateDep: new Date().toISOString().split("T")["0"],
  timeDep: new Date().toLocaleTimeString(),
  depArr: "ab",
  // showConnections: false
}
