import { rankWith, scopeEndsWith } from "@jsonforms/core";

export const stationDepControlTester = rankWith (
  3,
  scopeEndsWith("stationDep")
);

export const stationDestControlTester = rankWith (
  3,
  scopeEndsWith("stationDest")
);


