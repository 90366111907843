import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { schema } from "../scheme/prostate/schema";
import { uischema } from "../scheme/prostate/uischeme";
import { NumberRenderer, NumberTester } from '../renderers/prostateNumber';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { calcProstateDensity, calcProstateVolume } from "../modules/formulas";
import { WrongValueWarning } from './WrongValue';


const customRenderers = [
    ...materialRenderers,
    { renderer: NumberRenderer, tester: NumberTester }
];

var volumeWarningID = "wrongVolumeWarning";
var densityWarningID = "wrongDensityWarning";

/**
     * Hides or shows a warning above `elForWarning` to show that the input differs from the calculated result
     * @param oldValue Value from previous render
     * @param newValue New value in current render
     * @param calculatedValue Actual value based on calculations
     * @param warningEl The element containings the warning
     * @param elForWarning Input element for placing the warning above it
     * @param referenceEl The element the `elForWarning` takes it offset from to calculate proper position for warning
     * 
     * Note: Calcuations for placing the warning differ from the identically named function for PIRADS warning.
     */
function showOrHideWarning(oldValue: any, newValue: any, calculatedValue: any, warningEl: any, elForWarning: any, referenceEl: any) {
    oldValue = oldValue.toString().replace(",", ".");
    calculatedValue = calculatedValue.toString().replace(",", ".");

    if (newValue.toString().replace(",", ".") !== calculatedValue
        && newValue
    ) {
        if (elForWarning && warningEl && referenceEl) {
            warningEl.style.visibility = "visible";
            warningEl.style.left = (elForWarning.getBoundingClientRect().x - referenceEl.getBoundingClientRect().x) + "px";
            warningEl.style.top = (elForWarning.clientHeight * 2).toString() + "px"
        }
    } else if (newValue.toString().replace(",", ".") === calculatedValue
        && warningEl
    ) {
        warningEl.style.visibility = "hidden";
    }
}

function ProstateForm({ data, onChange }: any) {
    const [prevData, setPrevData] = useState(data);
    const prevDataRef = useRef(prevData);
    prevDataRef.current = prevData;
    const [volumeWarningTooltipVisible, setVolumeWarningTooltipVisible] = useState(false);
    const [densityWarningTooltipVisible, setDensityWarningTooltipVisible] = useState(false);

    var volumeWarningEl = document.getElementById(volumeWarningID);
    var densityWarningEl = document.getElementById(densityWarningID);
    var findingsDiv = document.getElementById("findingsDiv"); // the element volume is relative to, to calculate the position

    if (!_.isEqual(prevDataRef.current, data)) {
        // calculate volume and density if it didn't change
        if (data !== null && data !== undefined) {
            if (data.prostate.length
                && data.prostate.width
                && data.prostate.height
                && data.prostate.volume === prevDataRef.current.prostate.volume
            ) {
                data.prostate.volume = calcProstateVolume(data.prostate.length, data.prostate.width, data.prostate.height);
            }
            if (data.prostate.volume
                && data.psa
                && data.psa.value
                && (data.psa.density === prevDataRef.current.psa.density)
            ) {
                data.psa.density = calcProstateDensity(data.prostate.volume, data.psa.value);
            }
        }

        showOrHideWarning(
            prevDataRef.current.prostate.volume,
            data.prostate.volume,
            calcProstateVolume(data.prostate.length, data.prostate.width, data.prostate.height),
            volumeWarningEl,
            document.getElementById("#/properties/prostate/properties/volume"),
            findingsDiv
        );
        showOrHideWarning(
            prevDataRef.current.psa.density,
            data.psa.density,
            calcProstateDensity(data.prostate.volume, data.psa.value),
            densityWarningEl,
            document.getElementById("#/properties/psa/properties/density"),
            findingsDiv
        );

        let newData = _.cloneDeep(data);
        if (!_.isEqual(newData, prevDataRef.current)) {
            onChange(newData);
            setPrevData(newData);
        }
    }


    const handleOnChange = (props: any) => {
        let newData = _.cloneDeep(props.data);
        if (!_.isEqual(newData, data)) {
            onChange(newData);
        }
    };

    return (<>
        <WrongValueWarning id={volumeWarningID} setIsVisible={setVolumeWarningTooltipVisible} isVisible={volumeWarningTooltipVisible} />
        <WrongValueWarning id={densityWarningID} setIsVisible={setDensityWarningTooltipVisible} isVisible={densityWarningTooltipVisible} />
        <JsonForms
            data={data}
            renderers={customRenderers}
            cells={materialCells}
            schema={schema}
            uischema={uischema}
            onChange={handleOnChange}
        ></JsonForms>
    </>
    );
}

export default ProstateForm;
