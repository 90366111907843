import { JsonForms } from '@jsonforms/react';
import React, { useState, useEffect, useRef } from 'react';
import { LesionAreaHeaderProvider } from "../context/LesionAreaHeaderContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { schema } from "../scheme/lesions/lesionListSchema";
import { uischema } from "../scheme/lesions/lesionListUischeme";
import { EnumRenderer, EnumTester } from "../renderers/enum";
import { NumberRenderer, NumberTester } from '../renderers/lesionNumber';
import { calculatePirads } from '../modules/formulas';
import _ from 'lodash';
import { WrongValueWarning } from './WrongValue';

import { Card, CardHeader, CardContent, Collapse, IconButton, Avatar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme } from '../themes/card';

const customRenderers = [
    ...materialRenderers,
    { tester: EnumTester, renderer: EnumRenderer },
    { tester: NumberTester, renderer: NumberRenderer }
];

const overallPiradsWarningId = "overallPiradsWarning";

function getIdForLesionWarning(index: Number) {
    return `piradsWarning_${index}`
}

/**
     * Hides or shows a warning above `elForWarning` to show that the input differs from the calculated result
     * @param oldValue Value from previous render
     * @param newValue New value in current render
     * @param calculatedValue Actual value based on calculations
     * @param warningEl The element containings the warning
     * @param elForWarning Input element for placing the warning above it
     * @param referenceEl The element the `elForWarning` takes it offset from to calculate proper position for warning
     */
function showOrHideWarning(newValue: any, calculatedValue: any, warningEl: any, diffEls: number) {
    if (newValue !== calculatedValue
        && newValue
    ) {
        if (warningEl) {
            warningEl.style.visibility = "visible";
            warningEl.style.left = "32px";
            warningEl.style.bottom = `${282 + (diffEls * 60)}px`
        }
    } else if (newValue === calculatedValue
        && warningEl
    ) {
        warningEl.style.visibility = "hidden";
    }
}


/**
 * ...
 * @param focusLesion index of the lesion
 * @returns 
 */
function LesionListForm({ data, onChange, overallPirads, setOverallPirads, focusLesion, setFocusLesion }: any) {
    const [prevOverallPirads, setPrevOverallPirads] = useState(overallPirads);
    const [prevData, setPrevData] = useState(data);
    const prevDataRef = useRef(prevData);
    prevDataRef.current = prevData;
    const [warningStates, setWarningState] = useState([false, false, false, false]);
    const [overallPiradsWarningVisible, setOverallPiradsWarningVisible] = useState(false);
    const warningStatesRef = useRef(warningStates);
    warningStatesRef.current = warningStates;
    const [prevFocusLesion, setPrevFocusLesion] = useState(focusLesion);

    if (prevFocusLesion !== focusLesion) {
        console.log("FOCUSLESION")
        for (let j = 0; j < data.lesions.length; j++) {
            let tmp = document.getElementById(getIdForLesionWarning(j));
            if (tmp) {
                tmp.style.visibility = "hidden";
            }
        }
        setPrevFocusLesion(focusLesion);

        if (data.lesions[focusLesion]) {
            showOrHideWarning(
                data.lesions[focusLesion] ? data.lesions[focusLesion].pirads : 0,
                calculatePirads(
                    data.lesions[focusLesion].zone,
                    data.lesions[focusLesion].t2,
                    data.lesions[focusLesion].dwi,
                    data.lesions[focusLesion].dce
                ),
                document.getElementById(getIdForLesionWarning(focusLesion)),
                data.lesions.length - focusLesion - 1
            );
        }
    }

    useEffect(() => {
        const colors = ['#d40f22', '#3357FF', '#eb8634', '#c533ff'];
        const avatars = document.querySelectorAll('.MuiAvatar-root');
        avatars.forEach((avatar, index) => {
            const color = colors[index % colors.length];
            (avatar as HTMLElement).style.backgroundColor = color;
        });
    }, [document.querySelectorAll('.MuiAvatar-root').length]);

    function handleChange(lesionData: any, activeLesionIndex: number) {
        let newData = _.cloneDeep(data);
        newData.lesions[activeLesionIndex] = _.cloneDeep(lesionData.data);
        if (!_.isEqual(newData, data)) {
            onChange(newData);
        }
    }

    // useEffect because I don't want it to update when overallPirads changes. So we can set the spoken overallPirads level
    useEffect(() => {
        if (data.lesions.length !== prevDataRef.current.lesions.length) {
            setPrevData(data);
        } else {
            if (!_.isEqual(prevDataRef.current, data)) {
                let lesionData = data.lesions[focusLesion];
                // take care: lesionData = { "data": {...} } -> lesionData.data to access actual data
                // naming because I need to access 'data' var from top level

                var newData = _.cloneDeep(data);
                newData.lesions[focusLesion] = lesionData;
                if (prevDataRef.current.lesions[focusLesion].pirads === lesionData.pirads
                    || !lesionData.pirads
                ) {
                    // pirads unchanged, calculating pirads based on other lesion data changes:
                    newData.lesions[focusLesion].pirads = calculatePirads(
                        lesionData.zone,
                        lesionData.t2,
                        lesionData.dwi,
                        lesionData.dce
                    );
                }
                var maxPirads;
                for (let j = 0; j < newData.lesions.length; j++) {
                    if (newData.lesions[j].pirads && (maxPirads === undefined || maxPirads < parseInt(newData.lesions[j].pirads))) {
                        maxPirads = newData.lesions[j].pirads;
                    }
                }
                if (maxPirads) {
                    setOverallPirads(maxPirads);
                }
                showOrHideOverallPiradsWarning(maxPirads, overallPirads);

                // current lesion wrong value warning:
                showOrHideWarning(
                    lesionData.pirads,
                    calculatePirads(
                        lesionData.zone,
                        lesionData.t2,
                        lesionData.dwi,
                        lesionData.dce
                    ),
                    document.getElementById(getIdForLesionWarning(focusLesion)),
                    newData.lesions.length - focusLesion - 1
                );

                if (prevDataRef.current.lesions[focusLesion].pirads !== lesionData.pirads) {
                    onChange(newData);
                    setPrevData(newData);
                }
            }
        }
    }, [data])

    if (prevOverallPirads !== overallPirads) {
        setPrevOverallPirads(overallPirads);

        var maxPirads;
        for (let j = 0; j < data.lesions.length; j++) {
            if (data.lesions[j].pirads && (maxPirads === undefined || maxPirads < parseInt(data.lesions[j].pirads))) {
                maxPirads = data.lesions[j].pirads;
            }
        }

        showOrHideOverallPiradsWarning(maxPirads, overallPirads);
    }

    const addNewLesion = () => {
        var newData = _.cloneDeep(data);
        var maxLesions = 4;
        if (newData.lesions.length < maxLesions) {
            newData.lesions.push({
                "lesionNum": newData.lesions.length + 1,
                "zone": null,
                "side": null,
                "level": null,
                "size": 0.0,
                "t2": null,
                "dwi": null,
                "dce": null,
                "pirads": null,
            })
            onChange(newData);
            setFocusLesion(newData.lesions.length - 1);
        } else {
            console.warn(`Lesion mentioned by speech is out of range. Limit: ${maxLesions}`);
        }
    }

    const removeLesion = (index: number) => {
        var newData = _.cloneDeep(data);
        if (index !== newData.lesions.length - 1) {
            for (var i = index; i < newData.lesions.length; i++) {
                newData.lesions[i] = newData.lesions[i + 1];
            }
        }
        newData.lesions.pop();
        onChange(newData);
        if (index == focusLesion) {
            setFocusLesion(index - 1);
        } else if (index < focusLesion) {
            setFocusLesion(focusLesion - 1);
        }
    }

    return (
        <div>
            <WrongValueWarning
                id={overallPiradsWarningId}
                setIsVisible={setOverallPiradsWarningVisible}
                isVisible={overallPiradsWarningVisible}
            />
            <IconButton onClick={addNewLesion}><Avatar>+</Avatar></IconButton>
            <ThemeProvider theme={theme}>
                {data.lesions.map((lesion: any, index: any) => (
                    <Card key={index}>
                        <CardHeader
                            title={
                                <>
                                    <IconButton onClick={() => setFocusLesion(index)}><Avatar>{index + 1}</Avatar></IconButton>
                                    <IconButton onClick={() => removeLesion(index)}><DeleteIcon /></IconButton>
                                </>
                            }
                        />
                        <Collapse in={index === focusLesion}>
                            <CardContent>
                                <WrongValueWarning
                                    id={getIdForLesionWarning(index)}
                                    setIsVisible={(state: any) => {
                                        setWarningState((oldStates: any) => {
                                            var newStates = _.cloneDeep(oldStates);
                                            newStates[index] = state;
                                            return newStates;
                                        });
                                    }}
                                    isVisible={warningStatesRef.current[index]} />
                                <JsonForms
                                    data={lesion}
                                    renderers={customRenderers}
                                    cells={materialCells}
                                    schema={schema}
                                    uischema={uischema}
                                    onChange={(data: any) => handleChange(data, index)}
                                />
                            </CardContent>
                        </Collapse>
                    </Card>
                ))}
            </ThemeProvider>
        </div>
    );
};

export default LesionListForm;
function showOrHideOverallPiradsWarning(maxPirads: any, overallPirads: any) {
    let warningEl = document.getElementById(overallPiradsWarningId);
    if (overallPirads !== maxPirads
        && overallPirads) {
        if (warningEl) {
            warningEl.style.visibility = "visible";
            warningEl.style.left = "252px";
            warningEl.style.bottom = "118px";
        }
    } else if (overallPirads === maxPirads
        && warningEl) {
        warningEl.style.visibility = "hidden";
    }
}
