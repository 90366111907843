function calculatePirads(zone, t2, dwi, dce) {
  if (zone === undefined || zone === null) {
      return "undefined";
  }
  if (zone.includes("PZ")) {
      if (dwi === "3") {
          if (dce === "+") {
              return "4";
          }
          else if (dce === "-") {
              return "3";
          }
      }
      else {
          return dwi;
      }
  }
  else if (zone.includes("TZ")) {
      if (t2 === "3") {
          if (dwi === "5") {
              return "4";
          }
          else if (dwi === "1" || dwi === "2" || dwi === "3" || dwi === "4") {
              return "3";
          }
      }
      else {
          return t2;
      }
  }
  return "undefined";
}

/**
 * Calculate prostate volume. 2 digits after decimal point
 * @param {*} length 
 * @param {*} width 
 * @param {*} height 
 * @returns 
 */
function calcProstateVolume(length, width, height) {
    return (length * width * height * (Math.PI / 6)).toFixed(2);
}

/**
 * Calculate prostate density. 3 digits after decimal point
 * @param {*} volume
 * @param {*} psaValue in ng/ml
 * @returns 
 */
function calcProstateDensity(volume, psaValue) {
    return (psaValue / volume).toFixed(3);
}

export {
    calcProstateVolume,
    calcProstateDensity,
    calculatePirads
}
