import Header from './components/Header';
import React from 'react';
import { Link } from 'react-router-dom';
import './css/navigationButton.css';

function App() {

    return (
        <>
            <Header />
            <div style={{ "display": "flex", "flexDirection": "column", "alignItems": "center" }}>
                <Link to="/dvb">
                    <button className="navigationButton">Go to DVB Demo</button>
                </Link>
                <Link to="/pirads">
                    <button className="navigationButton">Go to PI-RADS Demo</button>
                </Link>
            </div>
        </>
    );
};

export default App;