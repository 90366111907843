export const schema = {
    type: "object",
    properties: {
        lesionNum: {
            type: "number",
        },
        zone: {
            type: "string",
            enum: [
                "TZa",
                "TZp",
                "PZa",
                "PZpl",
                "PZpm"
            ]
        },
        level: {
            type: "string",
            enum: [
                "Base",
                "Mid",
                "Apex"
            ]
        },
        side: {
            type: "string",
            enum: [
                "L",
                "R"
            ]
        },
        size: {
            type: "number",
            min: 0,
            max: 50,
            step: 0.1
        },
        t2: {
            type: "string",
            enum: [
                "1",
                "2",
                "3",
                "4",
                "5"
            ]
        },
        dwi: {
            type: "string",
            enum: [
                "1",
                "2",
                "3",
                "4",
                "5"
            ]
        },
        dce: {
            type: "string",
            enum: [
                "+",
                "-"
            ]
        },
        pirads: {
            type: "string",
            enum: [
                "1",
                "2",
                "3",
                "4",
                "5"
            ]
        }
    }
}

