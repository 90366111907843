
function applyHighlights(text, lengthPrevText) {
  let endOfOldValue = text.length - (text.length - lengthPrevText);
  if (text[endOfOldValue] === " ") {
      endOfOldValue += 1;
  }
  let markedText = text.substring(endOfOldValue, text.length);
  if (endOfOldValue < 0
      || markedText === ""
      || markedText === " ") {
      return text;
  }
  let finalText = "";

  finalText = text.substring(0, endOfOldValue);
  if (markedText[markedText.length-1] === "\n") {
    markedText = markedText.substring(0, markedText.length - 1);
    console.log(finalText + "<mark>" + markedText + "</mark>");
    return finalText + "<mark>" + markedText + "</mark>";
  }
  console.log(finalText + "<mark>" + markedText + "</mark>");
  return finalText + "<mark>" + markedText + "</mark>";
}

function handleScroll(divID) {
  var scrollTop = document.getElementById(divID + "Textarea")?.scrollTop;
  document.getElementById(divID + "backdrop")?.scrollTo({ top: scrollTop });

  var scrollLeft = document.getElementById(divID + "Textarea")?.scrollLeft;
  document.getElementById(divID + "backdrop")?.scrollTo({ left: scrollLeft });
}

export { applyHighlights, handleScroll };
