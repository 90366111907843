import { rankWith, scopeEndsWith } from "@jsonforms/core";

export const dateDepControlTester = rankWith (
  3,
  scopeEndsWith("dateDep")
);

export const returnJourneyDateControlTester = rankWith (
  3,
  scopeEndsWith("returnJourneyDate")
);
