import { rankWith, scopeEndsWith } from "@jsonforms/core";

export const timeDepControlTester = rankWith (
  3,
  scopeEndsWith("timeDep")
);

export const returnJourneyTimeControlTester = rankWith (
  3,
  scopeEndsWith("returnJourneyTime")
);
