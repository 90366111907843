export const ELEMENT_ID = {
  none: "",
  technology: "technologyDiv",
  medicalHistory: "medicalHistoryDiv",
  findings: "findingsDiv",
  prostateVolume: "prostateDiv",
  psa: "psaDiv",
  zones: "zonesDiv",
  lesions: "lesionsDiv",
  summary: "summaryDiv",
  remarks: "remarksDiv"
}
