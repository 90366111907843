import { useContext, useState } from "react";
import { saveAsTemplate, getTemplates } from "./localTemplateService";
import { AppStateProvider, AppStateContext } from "../context/AppStateContext";
import { applyHighlights, handleScroll } from "../modules/highlight_in_textarea";

// TODO:
/**
 * - editierte Vorlagen speichern in localStorage
 * 
 * - neue Vorlagen hinzufügen
 * 
 * - evtl. Titel für Vorlagen
 */

function SelectTemplate({ category, setState, modalId }: any) {
    const templates = getTemplates(category);
    const textareaId = "texarea_" + category;
    const editBtnId = "editBtn_" + category;
    const chooseBtnId = "chooseBtn_" + category;

    return (
        <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Vorlagen</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p><label>Vorlage:</label></p>
                        <div className="container-fluid">
                            {templates.map((template: any, index: number) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="col-sm-8">
                                            <textarea
                                                id={textareaId + index}
                                                disabled
                                                style={
                                                    {
                                                        "marginTop": "0",
                                                        "width": "100%",
                                                        "height": "150px",
                                                        "resize": "none",
                                                        "border": "2px solid lightgray",
                                                        "borderRadius": "5px"
                                                    }
                                                }>{template}</textarea>
                                        </div>
                                        <div className="col-sm-2">
                                            <button
                                                id={editBtnId + index}
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    var tArea = document.getElementById(textareaId + index);
                                                    // @ts-ignore
                                                    tArea.disabled = !tArea.disabled;
                                                    var editBtn = document.getElementById(editBtnId + index);
                                                    // @ts-ignore
                                                    editBtn.innerHTML = tArea.disabled ? "Bearbeiten" : "Fertig";
                                                }}>Bearbeiten</button>
                                        </div>
                                        <div className="col-sm-2">
                                            <button
                                                id={chooseBtnId + index}
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    var tArea = document.getElementById(textareaId + index);
                                                    // @ts-ignore
                                                    setState(tArea.value);
                                                }}>Auswählen</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface DictateWithTemplateProps {
    header: string;
    divID: string;
    category: string;
    state: string;
    setState: any;
    setAsrFocus: any;
};

function DictateWithTemplate(props: DictateWithTemplateProps) {
    const modalId = "modal_" + props.category;
    const [highlightedText, setHighlightedText] = useState("");
    const [prevState, setPrevState] = useState("");

    if (props.state !== prevState) {
        handleChange(props.state);
    }

    function handleChange(data: string) {
        setPrevState(data);
        if (prevState !== data) {
            setHighlightedText(applyHighlights(data, prevState.length));
            props.setState(data);
        }
        var el = document.getElementById(props.divID + "Textarea");

        if (el?.scrollHeight) {
            el.scrollTop = el?.scrollHeight;
        }
    };

    return (
        <>
            <SelectTemplate modalId={modalId} category={props.category} setState={props.setState}></SelectTemplate>
            <div id={props.divID} className="container-fluid formDivDark">
                <div className="row">
                    <div className="col-6 col-md-6">
                        <h2>{props.header}</h2>
                    </div>
                    <div className="col-6 col-md-6" style={{ "flexDirection": "row-reverse", "display": "flex", "alignItems": "center" }}>
                        <div>
                            <button className="btn btn-secondary" style={{ "height": "fit-content", "width": "-webkit-fill-available", "marginLeft": "10px", "fontSize": "0.8rem" }}>
                                ALS VORLAGE SPEICHERN
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-secondary"
                                style={{ "height": "fit-content", "width": "-webkit-fill-available", "marginLeft": "10px", "fontSize": "0.8rem" }}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target={"#" + modalId}
                            >VORLAGE LADEN</button>
                        </div>
                    </div>
                </div>

                <div className="highlight_container">
                    <div id={props.divID + "backdrop"} className="backdrop">
                        <div className="highlights" dangerouslySetInnerHTML={{ __html: highlightedText }}></div>
                    </div>
                    <textarea
                        id={props.divID + "Textarea"}
                        className='highlight_textarea'
                        value={props.state}
                        onChange={(e: any) => { handleChange(e.target.value) }}
                        onFocus={() => { props.setAsrFocus(props.divID); }}
                        onScroll={() => { handleScroll(props.divID); }}
                    ></textarea>
                </div>
            </div>
        </>
    );
}

export default DictateWithTemplate;
