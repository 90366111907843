import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function WrongValueWarning({id, setIsVisible, isVisible}: any) {
    return (
        <div
            id={id}
            style={{ position: "absolute", visibility: "hidden" }}
        >
            <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{ color: "#FF343B", position: "absolute" }}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            />
            <span
                style={{
                    visibility: isVisible ? "visible" : "hidden",
                    textAlign: "center",
                    position: "absolute",
                    zIndex: 1,
                    bottom: "100%",
                    left: "-92px", // relative element is 16px wide
                    width: "200px",
                    background: "white",
                    borderRadius: "5px",
                    border: "2px solid black"
                }}
            >Angegebener und berechneter Wert sind nicht konsistent.</span>
        </div>
    )
}

export {
    WrongValueWarning
}
