import { useState } from "react"

export const datatoView = [
  {
    "id": 1,
    "date": "2023-01-01",
    "sender_id": "user123",
    "user_input": "Hello, how are you?",
    "intent": "Greeting",
    "entities": [],
    "raw": {},
    "is_marked": false
  },
  {
    "id": 2,
    "date": "2023-01-02",
    "sender_id": "user456",
    "user_input": "Tell me a joke",
    "intent": "TellJoke",
    "entities": [{"entity": "content", "value": "joke"}],
    "raw": {},
    "is_marked": true
  },
  {
    "id": 1,
    "date": "2023-01-03",
    "sender_id": "user789",
    "user_input": "Search for restaurants",
    "intent": "SearchRestaurant",
    "entities": [{"entity": "restaurant", "value": "Hans im Glück"}],
    "raw": {},
    "is_marked": false
  }
]