import React, { useState } from "react";

// Default value is false, so that the LesionForms / rows which arn't wrapped 
// in LesionAreaHeaderProvider won't render a column header as well.
export const LesionAreaHeaderContext = React.createContext({ header: false, toggleHeader() {} });

/**
 * Provides a flag to render the Header / title for LesionForm elements.
 * @param param0 
 * @returns 
 */
export function LesionAreaHeaderProvider({children}: any) {
    const [renderHeader, setRenderHeader] = useState(true);
    const header = renderHeader ? true : false;
    const toggleHeader = () => setRenderHeader((t) => !t);
    return (
        <LesionAreaHeaderContext.Provider value={{header, toggleHeader}}>
            {children}
        </LesionAreaHeaderContext.Provider>
    )
}
