export const schema = {
    type: "object",
    properties: {
        prostate: {
            type: "object",
            properties: {
                length: {
                    type: "number",
                    title: "L"
                },
                width: {
                    type: "number",
                    title: "B"
                },
                height: {
                    type: "number",
                    title: "H"
                },
                volume: {
                    type: "number",
                    title: "Volumen"
                }
            }
        },
        psa: {
            type: "object",
            properties: {
                value: {
                    type: "number",
                    title: "PSA-Wert",
                },
                density: {
                    type: "number",
                    title: "PSA-Dichte",
                }
            }
        }
    }
}
