import React, { useEffect } from "react";
import "../css/Header.css";


export default function Header() {
    var isSmallScreen = window.innerWidth < 576;

    function NLUText() {
        return <div style={{ fontWeight: 700, color: "#fc9937", fontSize: "45px", padding: 0, paddingLeft: "5px", width: "fit-content" }}>NLU</div>
    }
    function NLUTextRow() {
        return <div className="row">
            <NLUText />
        </div>
    }

    return (
        <>
            <div id="Wrapper">
                <div id="Header_wrapper" className="">
                    <header id="Header">
                        <div id="" className="loading">
                            <div className="logo container-fluid">
                                <a id="logo" className="row" href="https://alphaspeech.de" title="alphaspeech. Spracherkennung. Made in Germany." data-height="60" data-padding="15">
                                    <div className="col-md-10 offset-md-2">
                                        <div className="row" style={{ flexWrap: "nowrap" }}>
                                            {/* <img className="logo-main" src="https://alphaspeech.de/wp-content/uploads/alphaspeech_logo_white.svg" data-retina="https://alphaspeech.de/wp-content/uploads/alphaspeech_logo_white.svg" data-height="" alt="alphaspeech. Spracherkennung. Made in Germany." data-no-retina />
                                            <div className="logo-main" style={{ fontWeight: 700, color: "#fc9937", fontSize: "45px" }}>NLU</div> */}
                                            <div style={{ fontWeight: 700, color: "#FFFFFF", fontSize: "45px", padding: 0, paddingLeft: "5px", width: "fit-content" }}>alpha</div>
                                            <div style={{ color: "#FFFFFF", fontSize: "45px", padding: 0, width: "fit-content" }}>speech</div>
                                            {isSmallScreen ? <></> : <NLUText />}
                                        </div>
                                        {isSmallScreen ? <NLUTextRow /> : <></>}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="mfn-main-slider mfn-rev-slider">
                            <p className="rs-p-wp-fix"></p>
                        </div>
                    </header>
                </div>
            </div>
        </>
    );
};
