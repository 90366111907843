import general_auth from "./general_auth"

export default {
  asr: {
    url: "https://stream.alphaspeech.de",
    /** 
     * rtcConf is of type RTCConfiguration:
     * {
     *    iceServers: [{urls:"...", credential:"...", username:"..."}]}
     * */
    rtcConf: {
      iceServers: [
        // {urls: "turn:turn.dev.alphaspeech.de", credential:"no", username:"no"}
        {urls: "turn:a.relay.metered.ca:443", credential:"y1PyHpi6B5t1ADo0", username:"08046b7d118c39a9cb0c050f"}
      ]
    },
    domainConfFormSpeech: {
        asr: {"domain": "de-DE_PI-RADS"},
        punctuation: {"enable": false},
        structure: {"enable": false},
        itn: {
            "enable": true,
            "convert_small_nums": true,
            "convert_datetime_structures": true,
            "convert_currency_structures": true,
            "convert_measurement_units": true
        },
        replacements: {
            "custom_replacements": {
                "T Z A": "TZa",
                "T Z P": "TZp",
                "P Z A": "PZa",
                "P Z P L": "PZpl",
                "P Z P M": "PZpm",
                "D C E": "DCE",
                "D W I": "DWI",
                "T 2": "T2",
                "T2 w": "T2w",
                "T 1 w": "T1w",
                "P S A": "PSA"
            }
        }
    },
    domainConfFreeText: {
      asr: {"domain": "de-DE_GENERIC+PI-RADS_free-text"},
      punctuation: {"enable": true, "mode": "dictate"},
      formatting: {"enable": true},
      structure: {"enable": true},
      itn: {
          "enable": true,
          "convert_small_nums": true,
          "convert_datetime_structures": true,
          "convert_currency_structures": true,
          "convert_measurement_units": true
      },
    //   replacements: {
    //     "custom_replacements": {
    //         "neue Zeile": "\n"
    //     }
    // }
  },
    auth: general_auth
  },
  nlu: {
    auth: general_auth,
    url: "https://formsnlu.alphaspeech.de/v1/nlu/",
    nluConfig: {"domain": "pirads", "language": "de-DE"}
  }
}
