import React from "react";

interface TimerpickerProps {
  id: string;
  title: string;
  description: string;
  value: any;
  handleChange: any;
  path: string;
}

export default function Timerpicker({ id, title, description, value, handleChange, path }: TimerpickerProps) {
  return (
    <>
      <div className="form-floating mb-3">
        <input
          className="form-control"
          id={id}
          type="time"
          min="00:00"
          max="23:59"
          onChange={(e: any) => handleChange(path, e.target.value)}
          value={value ? value.slice(0, 5) : value}
        />
        <label htmlFor={id}>{title}</label>
        <div className="form-text">
          {description}
        </div>
      </div>
    </>
  )
}
