import React, { useEffect, useState } from 'react';
import "../css/highlight_in_textarea.css";
import { applyHighlights, handleScroll } from '../modules/highlight_in_textarea';
import _ from 'lodash';

interface DictateProps {
    header: string;
    divID: string;
    data: string;
    setData: React.Dispatch<React.SetStateAction<string>>;
    setAsrFocus: any;
};

const Dictate: React.FC<DictateProps> = (props) => {
    const [highlightedText, setHighlightedText] = useState("");
    const [prevState, setPrevState] = useState("");

    if (props.data !== prevState) {
        handleChange(props.data);
    }

    function handleChange (data: string) {
        setPrevState(data);
        if (prevState !== data) {
            setHighlightedText(applyHighlights(data, prevState.length));
            props.setData(data);
        }
        var el = document.getElementById(props.divID + "Textarea") as HTMLInputElement;
        if(el?.setSelectionRange) {
            el.setSelectionRange(el.value.length, el.value.length);
        }
        if (el?.scrollHeight) {
            el.scrollTop = el?.scrollHeight;
        }
    };

    return (
        <div id={props.divID} className="formDivDark">
            <h2
                style={
                    {
                        "maxWidth": "40%"
                    }
                }>
                {props.header}
            </h2>

            <div className="highlight_container">
                <div id={props.divID + "backdrop"} className="backdrop">
                    <div className="highlights" dangerouslySetInnerHTML={{ __html: highlightedText }}></div>
                </div>
                <textarea
                    id={props.divID + "Textarea"}
                    className='highlight_textarea'
                    value={props.data}
                    onChange={(e: any) => { handleChange(e.target.value) }}
                    onFocus={() => { props.setAsrFocus(props.divID); }}
                    onScroll={() => { handleScroll(props.divID); }}
                ></textarea>
            </div>

        </div>
    );
}

export default Dictate;
