
interface SpeechCommand {
    phrases: (RegExp | string)[];
    callback: any;
}

class SpeechCommandProvider {
    __commands: SpeechCommand[] = [];

    /**
     * Add a speech command.
     * @param phrases Phrases, which will trigger the command, strings are parsed for a full match
     * @param callback Callback is called when a prhase matched with `runSpeechCommand`'s `text` argument
     */
    addSpeechCommand(phrases: (RegExp | string)[], callback: any) {
        phrases.forEach((phrase: (RegExp | string)) => {
            if (!((phrase instanceof RegExp) || (typeof phrase === typeof ""))) {
                console.error("Provided phrase neither is instance of `RegExp` nor `typeof \"\"`");
                return;
            }
        })

        this.__commands.push({phrases, callback} as SpeechCommand);
    }

    /**
     * Tries to match text for a speech command, returns true if one was executed.
     * @param text text to parse for speech command
     * @param ignoreCasingForStrings if casing for strings is relevant.
     * @returns `true` if a command was executed
     */
    tryRunSpeechCommand(text: string, ignoreCasingForStrings: boolean = true): boolean {
        var i, j;
        for (i = 0; i < this.__commands.length; i++) {
            var command = this.__commands[i];
            for (j = 0; j < command.phrases.length; j++) {
                var phrase = command.phrases[j];
                if (phrase instanceof RegExp) {
                    if (phrase.test(text)) {
                        command.callback();
                        return true;
                    }
                }
                else if (typeof phrase === typeof "") {
                    if (ignoreCasingForStrings) {
                        if (phrase.toLowerCase() === text.toLowerCase()) {
                            command.callback();
                            return true;
                        }
                    }
                    else {
                        if (phrase === text) {
                            command.callback();
                            return true;
                        }
                    }
                }
            }
        }
        console.log("No Speech command recognized.");
        return false;
    }
}

export type {
    SpeechCommand
}

export {
    SpeechCommandProvider
}
