import "../css/findingArea.css";
import FormDiv from "./FormDiv";
import ProstateForm from "./ProstateForm";
import { LesionAreaHeaderProvider } from "../context/LesionAreaHeaderContext";
import LesionListForm from "./LesionListForm";
import Dictate from "./Dictate";
import { ELEMENT_ID } from "../shared/element_ids";
import { JsonForms } from "@jsonforms/react";
import { EnumRenderer, EnumTester } from "../renderers/enum";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { uischeme } from "../scheme/overallPirads/overallPiradsUIScheme";
import { scheme } from "../scheme/overallPirads/overallPiradsScheme";


const customRenderers = [
    ...materialRenderers,
    { tester: EnumTester, renderer: EnumRenderer }
]

function FindingArea(props: any) {
/**
 * 1 check if changes are different from calculation
 * 2 display warning if different or remove warning if value and calculation are equal
 */
    return (
        <div id={props.divID} className="container-fluid formDivDark" onClick={(_x: any) => { props.setFocus(props.divID) }}>
            <div className="row">
                <h2>Befund</h2>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div style={{ "height": "100%" }} className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 p-0">
                                <FormDiv {...{ divID: ELEMENT_ID.prostateVolume, title: "Prostata und PSA" }}>
                                    <ProstateForm data={props.prostateData} onChange={props.setProstateData}></ProstateForm>
                                </FormDiv>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <FormDiv {...{ divID: ELEMENT_ID.lesions, title: "Läsionen" }}>
                        <LesionListForm data={props.lesionList} onChange={props.setLesionList} overallPirads={props.PIRADS} setOverallPirads={props.setPIRADS} focusLesion={props.focusLesion} setFocusLesion={props.setFocusLesion}></LesionListForm>
                    </FormDiv>
                    <FormDiv {...{ title: "PI-RADS Gesamt" }}>
                        <JsonForms
                            data={props.PIRADS}
                            onChange={(x: any) => props.setPIRADS(x.data.toString())}
                            renderers={customRenderers}
                            uischema={uischeme}
                            schema={scheme}
                            cells={materialCells}
                        />
                    </FormDiv>
                </div>
                <div className="col-sm-6">
                    <img style={{ "width": "100%" }} src="./pi_rads.png"></img>
                </div>
            </div>
        </div>
    );
}

export default FindingArea;
