import React, { useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { schema, uischema } from "../scheme/FormScheme";
import "../style/form.css";
import { materialCells, materialRenderers, MaterialHorizontalLayout } from '@jsonforms/material-renderers';
import InputControl from '../controls/InputControl';
import { stationDepControlTester, stationDestControlTester } from '../testers/bootstrapped/InputControlTester';
import DatepickerControl from '../controls/DatepickerControl';
import { dateDepControlTester, returnJourneyDateControlTester } from '../testers/bootstrapped/DatepickerControlTester';
import TimepickerControl from '../controls/TimepickerControl';
import { timeDepControlTester, returnJourneyTimeControlTester } from '../testers/bootstrapped/TimepickerControlTester';
import HorizontalLayoutControl from '../controls/HorizontalLayoutControl';
import { HorizontalLayoutControlTester } from '../testers/bootstrapped/HorizontalLayoutControlTester';
import ReturnJourneyControl from '../controls/ReturnJourneyControl';
import { ReturnJourneyControlTester } from '../testers/bootstrapped/ReturnJourneyControlTester';
import { EvalDVBDate, findRoutes, findStop } from '../service/DVB';
import { depDestControlTester } from '../testers/bootstrapped/DepDestControlTester';
import DepDestControl from '../controls/DepDestControl';
import Route from './Route';
import _ from "lodash";


// https://jsonforms.io/docs/tutorial/custom-renderers/
const renderers = [
  ...materialRenderers,
  { tester: stationDepControlTester, renderer: InputControl },
  { tester: stationDestControlTester, renderer: InputControl },
  { tester: dateDepControlTester, renderer: DatepickerControl },
  { tester: timeDepControlTester, renderer: TimepickerControl },
  { tester: returnJourneyDateControlTester, renderer: DatepickerControl },
  { tester: returnJourneyTimeControlTester, renderer: TimepickerControl },
  { tester: HorizontalLayoutControlTester, renderer: HorizontalLayoutControl },
  { tester: ReturnJourneyControlTester, renderer: ReturnJourneyControl },
  { tester: depDestControlTester, renderer: DepDestControl },
];

type FormProps = {
  data: any;
  onDataChange: any;
}

export default function DBForm(props: FormProps) {
  const [errors, setErrors] = useState([]);
  const [routes, setRoutes] = useState([<button key="2">btn1</button>, <label key="1">label1</label>]);
  let timeLabel: string;

  // Descriptions below can be changed depending on whether time is dep or arr time
  if (props.data.depArr === "ab") {
    timeLabel = "Die Uhrzeit, zu der die Fahrt beginnen soll";
  } else {
    timeLabel = "Die Uhrzeit, zu der die Fahrt enden soll";
  }
  schema.properties.timeDep.description = timeLabel;

  return (
    <>
      <div
        style={{
          border: "solid 1px",
          borderRadius: "0.6rem",
          padding: "1rem",
          borderColor: "lightgray",
        }}>
        <form onSubmit={(e) => { e.preventDefault() }}> { /**fsdf */}
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={props.data}
            cells={materialCells}
            renderers={renderers}
            onChange={({ data, errors }: any) => { props.onDataChange(data); setErrors(errors); console.log("DATA CHANGED", _.cloneDeep(data)); handleSubmitForm({...data, setRoutes: setRoutes });}}
          />
        </form>
      </div>
      <div id="routes-container">
        <h2>Routen</h2>
        {routes}
      </div>
    </>
  );
};

function NoRoutesFound(props: any) {
  return (
    <div key={"1"}>
      <p>
        Keine Verbindungen gefunden.
      </p>
    </div>
  );
}

async function handleSubmitForm(props: any) {
  // TODO: If looking for trips in a time span and trying to search for other trips in a time span when the first trips aren't
  // fully gathered yet you'll get trips for both in your view, so you'd need to cancel the old gatherings.
  console.log("handleSubmitForm props:", _.cloneDeep(props));
  if (hasEmptyProperty(props)) {
    return;
  }

  if (props.stationDep.id === "0" || props.stationDest.id === "0") {
    return;
  }

  let routes = document.getElementById("routes-container");
  routes?.classList.add("visible");
  props.setRoutes(<div className='loading-circle'></div>);

  var routeElements: any = [];
  var routeItems: any = [];
  var id: any = 0;

  addRoute(props, props.setRoutes)
  .then(() => {
    props.setRoutes(routeElements);
  })
  async function addRoute(_props: any, setRoutes: any) {
    return new Promise((resolve: any) => {
      findRoutes({
        dateDep: _props.dateDep,
        stationDep: _props.stationDep.id,
        stationDest: _props.stationDest.id,
        timeDep: _props.timeDep,
        depArr: _props.depArr
      })
      .then(async (response: any) => {
        if (response.Routes && response.Routes.length > 0) {
          console.log("Routes:", response.Routes);
          response.Routes.forEach((route: any) => {
            if (routeItems.length === 0
              || EvalDVBDate(route.PartialRoutes[0].RegularStops[0].DepartureTime) > EvalDVBDate(routeItems.slice(-1)[0].PartialRoutes[0].RegularStops[0].DepartureTime)
            )
            {
              routeItems.push(route);
              routeElements.push(
                <Route {...{ ...route, eventKey: id }} key={id} />
              )
              id += 1;
            }
          })
          setRoutes(routeElements);
          if (_props.routesUntil !== undefined) {
            // construct current Date object for latest departure time in query TODO: differentiate between departure and arrival
            let dateUntil = new Date(_props.dateDep);
            dateUntil.setHours(Number(_props.routesUntil.split(":")[0]), Number(_props.routesUntil.split(":")[1]));
            let latestDepartureStr: string = routeItems.slice(-1)[0].PartialRoutes[0].RegularStops[0].DepartureTime;
            let latestDepartureDate: Date = EvalDVBDate(latestDepartureStr);
            if (latestDepartureDate !== null
            && latestDepartureDate < dateUntil
            && _props.timeDep !== latestDepartureDate.toTimeString()) {
              let propsClone = _.cloneDeep(_props);
              latestDepartureDate.setMinutes(latestDepartureDate.getMinutes() + 1);
              propsClone.timeDep = latestDepartureDate.toTimeString();
              await addRoute(propsClone, props.setRoutes);
            }
          }
        } else {
          routeElements.push(<NoRoutesFound/>)
        }
        resolve();
      })
    });
  }
}

function hasEmptyProperty(obj: any): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof (obj[key]) === typeof ({})) {
        return hasEmptyProperty(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
        // property city can be null
        if (key === "city") {
          continue;
        }
        return true;
      }
    }
  }
  return false;
}
